import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@allthingswww/client-act-shared';
import { QRCode } from 'react-qr-svg';

// eslint-disable-next-line react/prefer-stateless-function
class QrCodeClass extends React.Component {
  render() {
    const { value, children } = this.props;
    return (
      <div>
        <QRCode
          bgColor="#FFFFFF"
          fgColor="#000000"
          level="Q"
          style={{ width: 256 }}
          value={value}
        />
        {children}
      </div>

    );
  }
}

const QrCode = ({ value = '', withPrint = false, children }) => {
  const componentRef = useRef();
  if (!withPrint) {
    return (
      <QrCodeClass
        value={value}
      />
    );
  }
  return (
    <div>
      <ReactToPrint
        trigger={() => <Button.Button variant>Print code</Button.Button>}
        content={() => componentRef.current}
        pageStyle="@page{ margin: 2.5cm; }"
      />
      <div style={{ marginTop: '1rem' }}>

        <QrCodeClass
          ref={componentRef}
          value={value}
        >
          {children}
        </QrCodeClass>
      </div>
    </div>
  );
};

export default QrCode;
