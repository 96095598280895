/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import { navigate } from '@reach/router';
import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { H3, H1 } from '@allthingswww/client-act-shared';
import Layout from '../components/Layout';
import QRCode from '../components/QrCode';

const Row = styled.div`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
margin: 1rem 0rem;
& > p {
  padding: 0rem 1rem;
}
`;

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

const DisplaySummaryOfEventsToBePaid = ({ name, itemsToBePaid = {}, total = 0 }) => (
  <div>
    <div>{name}</div>
    {Object.keys(itemsToBePaid).map((val, k) => (
      // eslint-disable-next-line react/no-array-index-key
      <Row key={k}>
        <H3>
          {val}
          {' '}
          :
          {' '}
          {Currency.format(itemsToBePaid[val] / 100)}
        </H3>
      </Row>
    ))}
    <Row>
      <H3>
        Total :
        {' '}
        {Currency.format(total / 100)}
      </H3>
    </Row>
  </div>
);

const DisplayDetailsOfEventsToBePaid = ({ eventCheckoutData = {} }) => {
  if (_.isEmpty(eventCheckoutData)) {
    return (<p>No checkout details</p>);
  }
  const { amountToBePaidDetails } = eventCheckoutData;
  return (
    <H3>
      ===========
      {
                      amountToBePaidDetails.map(({ fullName, eventItems }) => (
                        <Row>
                          <div>
                            Items for
                            {' '}
                            {fullName}
                            :
                            {' '}
                          </div>
                          <div>
                            {
                              Object.keys(eventItems).map((val, key) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <H3 key={key}>
                                  {' '}
                                  {eventItems[val].values[0]}
                                  {' '}
                                  {val}
                                </H3>
                              ))
                            }
                          </div>
                        </Row>
                      ))
                    }
    </H3>
  );
};

const EventPurchaseConfirmation = (props) => {
  const { stringifiedPaymentMethod, eventCheckoutData, total } = props.location.state ? props.location.state : { stringifiedPaymentMethod: '', eventCheckoutData: {} };

  return (
    <Layout title="Thank you for your payment!" subtitle="">
      <H1>
        Payment successful
      </H1>
      <p style={{
        marginBottom: '1.5rem',
      }}
      >
        Below is the QR code that you can download, a copy is sent your email address
      </p>
      <div style={{
        marginBottom: '1.5rem',
      }}
      >
        <QRCode
          withPrint
          value={stringifiedPaymentMethod}
        >
          <p style={{ paddingTop: '1rem' }}>Use the above code</p>
          <DisplaySummaryOfEventsToBePaid
            itemsToBePaid={eventCheckoutData.allItemsToBePaid}
            total={total}
          />
          <DisplayDetailsOfEventsToBePaid
            eventCheckoutData={eventCheckoutData}
          />
        </QRCode>
      </div>
    </Layout>
  );
};

export default EventPurchaseConfirmation;
